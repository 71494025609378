package xyz.qwewqa.relive.simulator.core.stage.buff

object NormalBarrierBuff : BuffEffect {
    override val name = "Normal Barrier"
    override val category = BuffCategory.Positive
}

object SpecialBarrierBuff : BuffEffect {
    override val name = "Special Barrier"
    override val category = BuffCategory.Positive
}
