package xyz.qwewqa.relive.simulator.core.presets.dress

import xyz.qwewqa.relive.simulator.core.presets.dress.back.cloud.*
import xyz.qwewqa.relive.simulator.core.presets.dress.back.flower.*
import xyz.qwewqa.relive.simulator.core.presets.dress.back.moon.FuMeiFan
import xyz.qwewqa.relive.simulator.core.presets.dress.back.moon.StageGirlHikari
import xyz.qwewqa.relive.simulator.core.presets.dress.back.moon.WorldMaya
import xyz.qwewqa.relive.simulator.core.presets.dress.back.snow.*
import xyz.qwewqa.relive.simulator.core.presets.dress.back.space.*
import xyz.qwewqa.relive.simulator.core.presets.dress.back.wind.FoolYachiyo
import xyz.qwewqa.relive.simulator.core.presets.dress.back.wind.ImperialCombatRevueFlowerDivisionSakura
import xyz.qwewqa.relive.simulator.core.presets.dress.back.wind.SakuraShingujiHikari
import xyz.qwewqa.relive.simulator.core.presets.dress.front.cloud.EmpressMeiFan
import xyz.qwewqa.relive.simulator.core.presets.dress.front.flower.EmperorAkira
import xyz.qwewqa.relive.simulator.core.presets.dress.front.moon.FaithMisora
import xyz.qwewqa.relive.simulator.core.presets.dress.front.snow.HierophantMichiru
import xyz.qwewqa.relive.simulator.core.presets.dress.front.space.CharityTsukasa
import xyz.qwewqa.relive.simulator.core.presets.dress.front.wind.StrengthFutaba
import xyz.qwewqa.relive.simulator.core.presets.dress.middle.cloud.MitsuhideKaren
import xyz.qwewqa.relive.simulator.core.presets.dress.middle.cloud.StageGirlMisora
import xyz.qwewqa.relive.simulator.core.presets.dress.middle.cloud.StageGirlNana
import xyz.qwewqa.relive.simulator.core.presets.dress.middle.flower.*
import xyz.qwewqa.relive.simulator.core.presets.dress.middle.snow.*
import xyz.qwewqa.relive.simulator.core.presets.dress.middle.moon.*
import xyz.qwewqa.relive.simulator.core.presets.dress.middle.space.WheelOfFortuneKaren
import xyz.qwewqa.relive.simulator.core.presets.dress.middle.wind.CatalinaKaoruko
import xyz.qwewqa.relive.simulator.core.presets.dress.middle.wind.HondaFutaba
import xyz.qwewqa.relive.simulator.core.presets.dress.middle.wind.TowerHikari

val playerDresses = listOf(
    AceMisora,
    AnastasiaJunna,
    CatalinaKaoruko,
    CharityTsukasa,
    DeathTamao,
    DevilKaoruko,
    EmperorAkira,
    EmpressMeiFan,
    FaithMisora,
    FoolYachiyo,
    FuMeiFan,
    HelldiverNana,
    HeroHikari,
    HierophantMichiru,
    HijikataToshizoTamao,
    HondaFutaba,
    HopeLalafin,
    HuntingRevueNana,
    ImperialCombatRevueFlowerDivisionSakura,
    IzanagiNana,
    JudgementShizuha,
    JusticeNana,
    KondoIsamiIchie,
    MitsuhideKaren,
    MoonAruru,
    NagakuraShinpachiFumi,
    NewYorkCombatRevueGemie,
    OrpheusFumi,
    ParisCombatRevueFlowerDivisionErika,
    SakuraShingujiHikari,
    SakuyahimeMahiru,
    SanadaMahiru,
    StageGirlClaudine,
    StageGirlHikari,
    StageGirlIchie,
    StageGirlJunna,
    StageGirlLalafin,
    StageGirlMahiru,
    StageGirlMeiFan,
    StageGirlMichiru,
    StageGirlMisora,
    StageGirlNana,
    StageGirlRui,
    StageGirlShizuha,
    StageGirlYachiyo,
    StrengthFutaba,
    SunIchie,
    TowerHikari,
    WheelOfFortuneKaren,
    WorldMaya,
    YellowTsukasa,
    RomeoKaren,
).associateBy { it.name }
